<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
   <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners webAppSpinner"
     
    />
     <!-- <div v-show="!isSpinner">
      <b-spinner
        variant="primary"
        v-if="isUpdating"
        large
        class="pageLoadSpinners"
        style="position: fixed"
      /> -->
    <!-- </div> -->
    <!-- <div v-show="!isSpinner"> -->
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">

          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link
              class="navbar-brand"
              to="/dashboard"
            >
              <span class="brand-logo" @click="logoClick()">
                <img
                  src="@/assets/images/tutorimages/Logo.png"
                  alt="logo"
                />
              </span>
              <!-- <h2 class="brand-text">
                {{ appName }}
              </h2> -->
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{'d-block': shallShadowBottom}"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >
      <!-- <vertical-nav-menu-items
        :items="navMenuItems"
        class="navigation navigation-main"
      /> -->
      <div class="sidebar_Wrapper" v-if="suspendErrorMessage == ''">
        <div class="current_online_session">
          <h2>Current Online Session</h2>
          <ul>
            <li v-for="(currentsession,index) in currentOnlineSession" :key="index" :class="{'sessionActive':activeIndex===index}" @click="isClick = true,goToDetailPage(currentsession.id,index)">
              <p class="time">{{currentsession.sessionBookTime}}</p>
              <h4>{{currentsession.title}}</h4>
              <p class="subject">{{currentsession.category}} <span v-if="currentsession.subCategory != ''"> > </span> {{currentsession.subCategory}}</p>
              <div class="image_title">
                <img :src="currentsession.image" />
                <p>{{currentsession.fullName}}</p>
              </div>
            </li>
          </ul>
          <span v-if="CurrentOnlineSessionArray.length == 0 && !isSpinner" style="color:red;font-weight:500;font-family: 'Rubik', sans-serif;font-size: 18px;">No session available.</span>
        </div>
         <div class="upcoming_session">
          <h2>Upcoming Session(s)</h2>
          <ul>
            <li v-for="(upcomingsession,index) in upcomingSession" :key="index" :class="{'sessionActive':upcomingIndex===index}" @click="isClick = true,goToDetailUpcomingPage(upcomingsession.id,index)">
              <p class="time">{{upcomingsession.sessionBookTime}}</p>
              <h4>{{upcomingsession.title}}</h4>
              <p class="subject">{{upcomingsession.category}}<span v-if="upcomingsession.subCategory != ''"> > </span>{{upcomingsession.subCategory}}</p>
              <div class="image_title">
                <img :src="upcomingsession.image" />
                <p>{{upcomingsession.fullName}}</p>
              </div>
            </li>
          </ul>
          <span v-if="upcomingSessionArray.length == 0 && !isSpinner" style="color:red;font-weight:500;font-family: 'Rubik', sans-serif;font-family: 'Rubik', sans-serif;font-size: 18px;">No session available.</span>
        </div>
        <!-- <span v-if="suspendErrorMessage!= ''" style="color:red;">{{suspendErrorMessage}}</span> -->
      </div>
      <!-- <vertical-nav-menu-items class="navigation navigation-main"/> -->
      <div style="text-align:center;position:absolute;bottom: 15px;width:100%">
        <!-- <a @click.prevent="$router.replace({name:'versionControl'}).catch(()=>{})">
          Version {{projectVersion}}
        </a> -->
      </div>
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import { version } from '@/../package.json'
import firebase from '@/utils/firebaseInit'
const db = firebase.firestore()

import navMenuItems from '@/navigation/vertical'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
// import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'
import { mapActions,mapGetters } from 'vuex';
import moment from 'moment'
import {
  BSpinner,
} from "bootstrap-vue";
// import { getRoutes } from '@/navigation/vertical/employeePages'

export default {
  components: {
    VuePerfectScrollbar,
    // VerticalNavMenuItems,
    BLink,
    BImg,
    BSpinner
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  data() {
    return{
      navItems:[],
      projectVersion:version,
      CurrentOnlineSessionArray:[],
      loginUserData:{},
      isSpinner:false,
      upcomingSessionArray:[],
      upcomingSplitId:'',
      activeIndex:'',
      upcomingIndex:'',
      suspendErrorMessage:'',
      isClick:false,
      callCurrentOnline:false
    }
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
     
    }
  },
  created(){
    clearInterval(this.timeInterval);
    // this.getSessions();
    this.loginUserData = JSON.parse(localStorage.getItem('currentUser'))
    if(this.loginUserData && this.loginUserData.status !== 2){
      this.suspendErrorMessage = '';
      this.getOnlineCurrentLists();
      this.getupComingSession();
      this.getCategories();
      this.getSubCategories();
      this.getUsers();
    }else{
      this.suspendErrorMessage = 'User is Suspended'
    }
    this.$root.$on('startSession',(id) => {
      console.log('startSessionstartSession')
      this.upcomingSplitId = id;
        this.getCategories();
        this.getSubCategories();
        this.getUsers();
      this.getupComingSession();
      setTimeout(() => {
        this.getOnlineCurrentLists();
      },3000)
    })
    var self = this;
   self.timeInterval = setInterval(function() {
      // console.log(self.CurrentOnlineSessionArray,self.upcomingSessionArray,'arrayyyy')
      if(self.upcomingSessionArray.length > 0 && self.upcomingSessionArray != undefined){
        self.upcomingSessionArray.forEach(_itm => {
          if(_itm.startTime.getTime() <= new Date().getTime()){
            self.getupComingSession();
            setTimeout(() => {
              self.getOnlineCurrentLists();
            },3000)
          }
        })
      }
      if(self.CurrentOnlineSessionArray.length > 0 && self.CurrentOnlineSessionArray != undefined){
         self.CurrentOnlineSessionArray.forEach((_itm1,_idx) => {
          if(_itm1.endTime.getTime() <= new Date().getTime()){
            console.log('_idx',_idx);
              self.CurrentOnlineSessionArray.splice(_idx,1);
          }
        })
      }
    },5000)
    
  },
  methods:{
  ...mapActions({
    'getOnlineCurrentLists' : 'currentOnline/getOnlineCurrentData',
    'getupComingSession' : 'upComingSession/getupComingSessionData',
    getCategories:'categoryManagement/getCategoriesData',
    getSubCategories:'categoryManagement/getSubCategoriesData',
    getUsers:'userManagement/getUsersData',
  }),
    //CALL EMIT FOR GO TO DETAIL PAGE OF SESSION (Shraddha)
    goToDetailPage(id,index){
      this.activeIndex = index;
      this.upcomingIndex = '';
      this.$root.$emit('fetchSessionDetail',id);
    },
    //CALL EMIT FOR GO TO DETAIL PAGE OF SESSION (Shraddha)
    goToDetailUpcomingPage(id,index){
      this.activeIndex = '';
      this.upcomingIndex = index;
      this.$root.$emit('fetchSessionDetail',id)
    },
    //CHECKING DATE FOR DISPLAY ARRAY IN CURRENT ONLINE SESSION OR NOT (Shraddha)
    checkDate(date1,date2){
      if(new Date(date1.seconds*1000) <= new Date() && new Date(date2.seconds*1000) >= new Date()){
        return true;
      }else{
        return false;
      }
    },
    //WHEN TUTORLINE LOGO CLICK(Shraddha)
    logoClick(){
      var self = this;
        if(self.CurrentOnlineSessionArray.length > 0){
          self.goToDetailPage(self.CurrentOnlineSessionArray[0].id,0)
        }else if(self.CurrentOnlineSessionArray.length == 0 && self.upcomingSessionArray.length > 0){
          self.goToDetailUpcomingPage(self.upcomingSessionArray[0].id,0)
        }
    }
  },
  computed:{
     ...mapGetters({
        getOnlineCurrentList: "currentOnline/getOnlineCurrent",
        getupComingSessionList: "upComingSession/getupComingSession",
        getUsersList:'userManagement/getUsers',
        getCategoryList:'categoryManagement/getCategories',
        getSubCategoryList:'categoryManagement/getSubCategories',
     }),
     // FOR GETTING CURRENT_ONLINE SESSION DATA (Shraddha)
    currentOnlineSession(){
      // debugger // eslint-disable-line
      var self = this;
        self.CurrentOnlineSessionArray = [];
        var index = 0;
        var found = false;
        self.isSpinner = true;
        // setTimeout(() => {
        // self.getOnlineCurrentLists().then(() => {
          try{
            self.getOnlineCurrentList.forEach(currentSession => {
              found = false;
              index++;
              // console.log('getOnlineCurrentList',self.getOnlineCurrentList,self.loginUserData);
              if(currentSession.tutor && currentSession.learnerId && self.checkDate(currentSession.startTime,currentSession.endTime)){
                if(currentSession.tutor === self.loginUserData.id){
                  var learnerindex = self.getUsersList.findIndex(item => {
                      return item.id == currentSession.learnerId
                  })
                  var tutorindex = self.getUsersList.findIndex(item => {
                      return item.id == currentSession.tutor
                  })
                  var catindex = self.getCategoryList.findIndex(item => {
                      return item.id == currentSession.category 
                  })
                  var subcatindex = self.getSubCategoryList.findIndex(item => {
                      return item.id == currentSession.subCategory 
                  })
                  var obj = {
                    id:currentSession.id,
                    sessionBookTime: moment(currentSession.startTime.seconds*1000).format("hh:mm A")+" to "+
                                    moment(currentSession.endTime.seconds*1000).format("hh:mm A"),
                    fullName : `${self.getUsersList[learnerindex].firstName} ${self.getUsersList[learnerindex].lastName}`,
                    image : self.getUsersList[learnerindex].profilePicture ? self.getUsersList[learnerindex].profilePicture : 'https://firebasestorage.googleapis.com/v0/b/tutorlineemergency.appspot.com/o/ProfileImage%2Fblank-profile-picture-973460__480.webp?alt=media&token=6b92dae1-c395-4f08-a626-265b1c89e88f',
                    title:currentSession.sessionDetails.name,
                    category:self.getCategoryList[catindex].categoryName,
                    subCategory:subcatindex > -1 ? self.getSubCategoryList[subcatindex].subCategoryName : '',
                    endTime:new Date(currentSession.endTime.seconds*1000)
                  }
                  if(self.CurrentOnlineSessionArray.length == 0){
                    self.CurrentOnlineSessionArray.push(obj);
                  }else{
                    self.CurrentOnlineSessionArray.forEach(data => {
                      if(data.id == currentSession.id){
                        found = true;
                      }
                    })
                    if(!found){
                      self.CurrentOnlineSessionArray.push(obj);
                    }
                  }
                }else if(currentSession.learnerId === self.loginUserData.id){
                  var learnerindex = self.getUsersList.findIndex(item => {
                      return item.id == currentSession.learnerId
                  })
                  var tutorindex = self.getUsersList.findIndex(item => {
                      return item.id == currentSession.tutor
                  })
                  var catindex = self.getCategoryList.findIndex(item => {
                      return item.id == currentSession.category 
                  })
                  var subcatindex = self.getSubCategoryList.findIndex(item => {
                      return item.id == currentSession.subCategory 
                  })
                  var obj = {
                    id:currentSession.id,
                    sessionBookTime: moment(currentSession.startTime.seconds*1000).format("hh:mm A")+" to "+
                                    moment(currentSession.endTime.seconds*1000).format("hh:mm A"),
                    fullName : `${self.getUsersList[tutorindex].firstName} ${self.getUsersList[tutorindex].lastName}`,
                    image : self.getUsersList[tutorindex].profilePicture ? self.getUsersList[tutorindex].profilePicture : 'https://firebasestorage.googleapis.com/v0/b/tutorlineemergency.appspot.com/o/ProfileImage%2Fblank-profile-picture-973460__480.webp?alt=media&token=6b92dae1-c395-4f08-a626-265b1c89e88f',
                    title:currentSession.sessionDetails.name,
                    category:self.getCategoryList[catindex].categoryName,
                    subCategory:subcatindex > -1 ?self.getSubCategoryList[subcatindex].subCategoryName : '',
                    endTime:new Date(currentSession.endTime.seconds*1000)
                  }
                  if(self.CurrentOnlineSessionArray.length == 0){
                    self.CurrentOnlineSessionArray.push(obj);
                  }else{
                    self.CurrentOnlineSessionArray.forEach(data => {
                      if(data.id == currentSession.id){
                        found = true;
                      }
                    })
                    if(!found){
                      self.CurrentOnlineSessionArray.push(obj);
                    }
                  }
                }
              }else{
                self.isSpinner = false;
              }
            })
            if(index == self.getOnlineCurrentList.length){
              // console.log('self.CurrentOnlineSessionArray',self.CurrentOnlineSessionArray);
              self.isSpinner = false;
              if(self.CurrentOnlineSessionArray.length > 0 && !self.isClick){
                console.log('############',self.CurrentOnlineSessionArray);
                self.goToDetailPage(self.CurrentOnlineSessionArray[0].id,0)
              }
              return self.CurrentOnlineSessionArray;
            }else{
              return [];
            }
          }catch(error){
            console.log('error');
            self.isSpinner = false;
            return [];
          }
        // })
      // },3000)
    },
    // FOR GETTING UPCOMING SESSION DATA (Shraddha)
    upcomingSession(){
      debugger // eslint-disable-line
      var self = this;
      // self.getupComingSession().then(() => {
        self.upcomingSessionArray = [];
        var index = 0;
        var found = false;
        self.isSpinner = true;
          // setTimeout(() => {
            try{
              // console.log('getupComingSession',self.getupComingSessionList);
              self.getupComingSessionList.forEach((items) => {
                found = false;
                index++;
                if(items.tutor && items.learnerId && items.startTime?.seconds*1000 >= new Date().getTime()){
                  if(items.tutor === self.loginUserData.id){
                  var learnerindex = self.getUsersList.findIndex(item => {
                      return item.id == items.learnerId
                  })
                  var tutorindex = self.getUsersList.findIndex(item => {
                      return item.id == items.tutor
                  })
                  var catindex = self.getCategoryList.findIndex(item => {
                      return item.id == items.category 
                  })
                  var subcatindex = self.getSubCategoryList.findIndex(item => {
                      return item.id == items.subCategory 
                  })
                    var object = {
                      bookingId: items.bookingId,
                      id: items.id,
                      sessionBookTime: moment(items.startTime.seconds*1000).format("hh:mm A")+" to "+
                                      moment(items.endTime.seconds*1000).format("hh:mm A"),
                      fullName : `${self.getUsersList[learnerindex].firstName} ${self.getUsersList[learnerindex].lastName}`,
                      image : self.getUsersList[learnerindex].profilePicture ? self.getUsersList[learnerindex].profilePicture : 'https://firebasestorage.googleapis.com/v0/b/tutorlineemergency.appspot.com/o/ProfileImage%2Fblank-profile-picture-973460__480.webp?alt=media&token=6b92dae1-c395-4f08-a626-265b1c89e88f',
                      title:items.sessionDetails.name,
                      category:self.getCategoryList[catindex].categoryName,
                      subCategory:subcatindex > -1 ?self.getSubCategoryList[subcatindex].subCategoryName : '',
                      startTime:new Date(items.startTime.seconds*1000)
                    }
                    if(self.upcomingSessionArray.length == 0){
                      self.upcomingSessionArray.push(object);
                    }else{
                      self.upcomingSessionArray.forEach(data => {
                        if(data.id == items.id){
                          found = true;
                        }
                      })
                      if(!found){
                        self.upcomingSessionArray.push(object);
                      }
                    }
                  }else if(items.learnerId === self.loginUserData.id){
                      var learnerindex = self.getUsersList.findIndex(item => {
                          return item.id == items.learnerId
                      })
                      var tutorindex = self.getUsersList.findIndex(item => {
                          return item.id == items.tutor
                      })
                      var catindex = self.getCategoryList.findIndex(item => {
                          return item.id == items.category 
                      })
                      var subcatindex = self.getSubCategoryList.findIndex(item => {
                          return item.id == items.subCategory 
                      })
                    var object = {
                      bookingId: items.bookingId,
                      id: items.id,
                      sessionBookTime: moment(items.startTime.seconds*1000).format("hh:mm A")+" to "+
                                    moment(items.endTime.seconds*1000).format("hh:mm A"),
                      fullName : `${self.getUsersList[tutorindex].firstName} ${self.getUsersList[tutorindex].lastName}`,
                      image : self.getUsersList[tutorindex].profilePicture ? self.getUsersList[tutorindex].profilePicture : 'https://firebasestorage.googleapis.com/v0/b/tutorlineemergency.appspot.com/o/ProfileImage%2Fblank-profile-picture-973460__480.webp?alt=media&token=6b92dae1-c395-4f08-a626-265b1c89e88f',
                      title:items.sessionDetails.name,
                      category:self.getCategoryList[catindex].categoryName,
                      subCategory:subcatindex > -1 ?self.getSubCategoryList[subcatindex].subCategoryName : '',
                      startTime:new Date(items.startTime.seconds*1000)
                    }
                    if(self.upcomingSessionArray.length == 0){
                      self.upcomingSessionArray.push(object);
                    }else{
                      self.upcomingSessionArray.forEach(data => {
                        if(data.id == items.id){
                          found = true;
                        }
                      })
                      if(!found){
                        self.upcomingSessionArray.push(object);
                      }
                    }
                  }
                }
              });
              
              // console.log('self.upcomingSessionArray',self.upcomingSessionArray);
              if(self.getupComingSessionList.length === index){
                self.isSpinner = false;
                if(self.CurrentOnlineSessionArray.length == 0 && self.upcomingSessionArray.length > 0 && !self.isClick){
                  self.goToDetailUpcomingPage(self.upcomingSessionArray[0].id,0)
                }
                if(self.upcomingSessionArray.length == 0 && self.CurrentOnlineSessionArray.length == 0){
                   self.goToDetailUpcomingPage('','')
                }
                if(self.upcomingSplitId != ''){
                  var index1 = self.upcomingSessionArray.findIndex(item => {
                    return item.id == self.upcomingSplitId  
                  })
                  if(index1 > -1){
                    self.upcomingSessionArray.splice(index1,1);
                  }
                 
                }
                return self.upcomingSessionArray;
              }else{
                return [];
              }
            }catch(error){
              console.log(error);
              self.isSpinner = false;
              return [];
            }
      // })
      // },3000)
    }, 
  }
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
<style scoped>
.main-menu .shadow-bottom {
    margin-top: 0rem;
    background: none;
}
.main-menu .navbar-header .navbar-brand .brand-logo img {
    max-width: 192px;
    width:100%;
}
.current_online_session img,.upcoming_session img{
    width: 50px;
    height: 50px;
    border-radius: 50px;
}
.current_online_session ul li,.upcoming_session ul li {
  background-color:#EFF4F6;
  border-radius: 15px;
    padding: 14px 19px;
    margin-bottom: 10px;
    cursor: pointer;
}
.current_online_session ul li.sessionActive,
.upcoming_session ul li.sessionActive
{
    background-color: #DAF2FF;
}
.webAppSpinner{
  z-index: 999999;
  position: absolute;
  top: 20%;
  right: 0px;
  left: 39% !important;
}
.current_online_session h2 {
    /* padding: 25px 0px 0px; */
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    line-height: 29px;
}
.image_title {
    display: flex;
    align-items: center;
}
p.time {
  font-size: 13px;
  line-height: 22px;
  color: #112556;
  font-family: 'Rubik', sans-serif;
   margin-bottom: 0!important;
   font-weight: 500;
}
.image_title p {
    margin-bottom: 0!important;
    margin-left: 10px;
    font-size: 14px;
    line-height: 22px;
    color: #112556;
    text-transform: capitalize;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
}
.sidebar_Wrapper {
    padding: 23px 30px;
}
.sidebar_Wrapper h2 {
    color: #2C2C2C;
    font-size: 22px;
    font-weight: 500;
    line-height: 29px;
     font-family: 'Rubik', sans-serif;
}
.sidebar_Wrapper h4 {
    color: #112556;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 0px;
    font-family: 'Rubik', sans-serif;
    margin-top: 3px;
}

.sidebar_Wrapper ul li p.subject {
  font-size: 13px;
  font-family: 'Rubik';
  line-height: 20px;
  text-transform: capitalize;
  margin-bottom: 5px;
}
.navbar-header.expanded {
    border-bottom: 1px solid #B1D9F2;
    height: 94px;
    /* text-align: center; */
}

.navbar-header.expanded span.brand-logo img {
    margin: 0 auto;
}

[dir] .main-menu .navbar-header .navbar-brand {
    margin: 0;
    line-height: 93px;
    margin: 0 auto;
    display: inline-block;
}

.main-menu .navbar-header {padding: 0px!important;}
.main-menu .navbar-header li.nav-item {
    margin: 0 auto;
}
.upcoming_session {
    margin-top: 35px;
}
</style>
