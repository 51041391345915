<template>

 <!-- <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="outline-primary"
      class="mr-2"
      :to="{
              name: 'booking-session',
              params: { name: $route.params.name, id: $route.params.id },
            }"
    >
      Book Session
    </b-button> -->

  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >   
    <template #button-content>     
      <div class="d-sm-flex d-none user-nav">       
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.fullName || userData.username }}
        </p>
        <span class="user-status">{{firstLetterCapital(userData.role) }}</span>
      </div>
      <b-avatar
        size="40"
        v-if="imageUrl==''"
        :src="userData.avatar"
        variant="dark"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
      </b-avatar>
      <!-- <b-avatar
        v-else
        size="40"
        :src="imageUrl"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      > -->
        <!-- <feather-icon
          v-if="!userData.fullName"
          icon="UserIcon"
          size="22"
        /> -->
      <!-- </b-avatar> -->
    </template>

    <!-- <b-dropdown-item
      :to="{ name: 'pages-profile'}"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>Profile</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'apps-email' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="MailIcon"
        class="mr-50"
      />
      <span>Inbox</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'apps-todo' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="CheckSquareIcon"
        class="mr-50"
      />
      <span>Task</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'apps-chat' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="MessageSquareIcon"
        class="mr-50"
      />
      <span>Chat</span>
    </b-dropdown-item>

    <b-dropdown-divider />

    <b-dropdown-item
      :to="{ name: 'pages-account-setting' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="SettingsIcon"
        class="mr-50"
      />
      <span>Settings</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'pages-pricing' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="CreditCardIcon"
        class="mr-50"
      />
      <span>Pricing</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'pages-faq' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="HelpCircleIcon"
        class="mr-50"
      />
      <span>FAQ</span>
    </b-dropdown-item> -->
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Logout</span>
    </b-dropdown-item></b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,BButton 
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter'
import firebase from "@/utils/firebaseInit";
export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BButton 
  },
  data() {
    return {
      userData: {},
      avatarText,
      imageUrl:''
    }
  },
  created() {
    var self = this
    if(localStorage.getItem('currentUser')){

      self.userData = JSON.parse(localStorage.getItem('currentUser'))
      console.log('userData',self.userData);
    }
    self.$root.$on('changeProfile',(imageUrl)=>{
      self.imageUrl = imageUrl
    })   
    self.$root.$on('changeLoggedUserName',(username)=>{
      console.log(username,"username")
      self.userData.fullName = username;
      self.userData.username = username;
    })
    self.$root.$on('logoutUser',()=>{
      self.logout()
    })
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      // Remove userData from localStorage
      localStorage.removeItem('userData')
      // Reset ability
      this.$ability.update(initialAbility)
      //FIREBASE COLLECTION USER DETAIL
      localStorage.removeItem('currentUser')     
       firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace({
            name: 'auth-login' 
          });
        });
    
    },
    firstLetterCapital(userRole){
      // debugger
      var role = '';
      if(userRole && userRole.length == 1){
        role =  userRole[0].charAt(0).toUpperCase() + userRole[0].slice(1);
      }else{
        let rolearray = []
        userRole?.forEach(element => {
          rolearray.push(element.charAt(0).toUpperCase() + element.slice(1))
        });
        role = rolearray.join(',')
      }
      return role;
    }
  },
  beforeDestroy() {
    this.$root.$off('logoutUser')
  }
}
</script>

<style scoped>
html .content.app-content {
    background-color: #f2faff;
}
.navbar-floating .header-navbar-shadow{
  background:none!important;
}
.header-navbar .navbar-container ul.navbar-nav li a.dropdown-user-link .user-status
{
  font-size: 11px;
  line-height: 13px;
  font-family: 'Rubik', sans-serif;
    font-weight: 400;
    color: #2c2c2c;
    letter-spacing: 0.5px;
}
.header-navbar .navbar-container ul.navbar-nav li a.dropdown-user-link .user-name
{
 font-size: 14px;
    font-weight: 600!important;
    font-family: 'Rubik', sans-serif;
    line-height: 17px;
    color: #2C2C2C;
}
</style>