<template>
<div>
    <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners"
      style="z-index:999999;margin-left:-160px; margin-top:350px;"
    />
   <!-- <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners"
      style="z-index:999999;margin-left:-160px; margin-top:350px;"
    />
     <div v-show="!isSpinner">
      <b-spinner
        variant="primary"
        v-if="isUpdating"
        large
        class="pageLoadSpinners"
        style="position: fixed"
      />
    </div> -->
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >
    <!-- Content Left -->
    <b-col class="content-header-left mb-2" cols="12" md="8">
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <div class="breadcrumb-wrapper">
          
          </div>
        </b-col>
      </b-row>
    </b-col>
  
   
  </b-row>
</div>
</template>

<script>
import Vue from 'vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
  BBreadcrumb,
  BBreadcrumbItem,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BButton,
  BSpinner,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BListGroup,
  BListGroupItem,
  BForm,
  BFormFile,
  BFormTextarea,
  BFormSelect,
  BFormRadio,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import firebase from "firebase"
export default {
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
 
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BSpinner,
    BFormCheckbox,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    vSelect,
    BListGroup,
    BListGroupItem,
    BFormFile,
    BFormSelect,
    BFormTextarea,
    BFormRadio,
    ValidationProvider, ValidationObserver,
  },
   data() {
    return {
     isSpinner:false
    };
  },
  
   
  
};
</script>
